"use client"
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Link from "../ui/link";
import FaqData from "../faq/faq-data";
import { useEffect } from 'react';

const Faq = ({courseDetails, screen}: any) => {    
    useEffect(() => {
        if (typeof window !== 'undefined') {
          const AOS = require('aos');
          AOS.init();
        }
      }, []);
    return (
        <section className="pt-7 lg:py-10 md:py-10 sm:py-10">
            <div className="container">
                <div data-aos="fade-up" data-aos-duration="1000">
                    <div className="grid grid-cols-1 gap-0 md:mb-8 mb-4">
                        {courseDetails?.seo_detail_paid_campaign?.title3
                            ?
                            <h2 className="text-center uppercase text-3xl lg:text-[35px] md:text-[35px] text-[#302A33] font-semibold leading-tight">
                                {courseDetails?.seo_detail_paid_campaign?.title3
                                }
                            </h2>
                            : <h2 className={`${screen === 'modules' ? 'capitalize' : 'uppercase'} text-center  text-3xl lg:text-[50px] md:text-[50px] text-[#302A33] font-semibold leading-tight`}>
                                { screen === 'modules' ? 'modules' : "Faq's"} 
                            </h2> 
                            }
                        {/* <h2 className="text-center uppercase text-3xl lg:text-[50px] md:text-[50px] text-[#302A33] font-semibold leading-tight">
                            {courseDetails?.seo_detail_paid_campaign?.title3
                                ? courseDetails?.seo_detail_paid_campaign?.title3
                                : `Faq's`}
                        </h2> */}
                    </div>
                    <div className={`grid grid-cols-1 lg:grid-cols-8 md:grid-cols-8 gap-0 ${screen === 'modules' && 'lg:grid-cols-12'}`}>
                        <div className={`col-start-1 ${screen === 'modules' ? "lg:col-start-2": "lg:col-start-3" }  md:col-start-2 col-span-1 lg:col-span-4 md:col-span-6 ${screen === 'modules' && 'col-start-1 lg:col-start-2 md:col-start-1 md:col-span-8 lg:col-span-6'}`}>
                            <FaqData courseDetails={courseDetails} screen={screen}/>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-col items-center">
                    <Link href="/faq" variant="viewall" className="mt-5 lg:mt-[30px] md:mt-[30px]">View All</Link>
                </div> */}
            </div>
        </section>
    );
}

export default Faq;